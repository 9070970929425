<template>
  <DashboardEquipment :screenId="display && display.screenId.toString()" />
</template>

<script>
import DashboardEquipment from "@/views/private/DashboardEquipment";

export default {
  name: "DashboardCustom",
  components: {
    DashboardEquipment
  },
  props: {
    display: {
      type: Object,
      required: false
    }
  },
  created() {
    if (!this.display?.screenId) {
      this.$router.replace("/dashboard/search");
    }
  }
};
</script>

<style></style>
